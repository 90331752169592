
import { useRouter, useRoute } from "vue-router";
import {
  defineComponent,
  inject,
  ref,
  Ref,
  onMounted,
  reactive,
  watch,
} from "vue";
import Doctor from "../components/Doctor.vue";
import { useStore } from "../store";
import { useI18n } from "vue-i18n";
import { notification } from "ant-design-vue";
import Slots from "../components/Slots.vue";
import { assign, findIndex } from "lodash";
import { AxiosStatic } from "axios";
import { ExclamationCircleFilled } from "@ant-design/icons-vue";
import {
  AppointmentType,
  OrderExt,
  PatientType,
  IdType,
  UserProfile,
  RuleObjectExt,
  SlotModel,
} from "../../types";
import { actions, slotsConfig } from "../utils/const";
import { PhoneNumberListItem } from "@hd2/common/types";
import { usePermissions } from "../composable/usePermissions";
import phonePrefixes from "@hd2/common/src/utils/phonePrefixes.json";
import moment, { Moment } from "moment";
import { Form } from "ant-design-vue";

const useForm = Form.useForm;

const phonePrefixOptions: Record<string, PhoneNumberListItem> = phonePrefixes;

interface OrderShortModel {
  symptoms: string;
  intraRegulationAgreement: boolean;
  date: Moment;
  slotIndex: number;
}

export const OrderShortComponent = defineComponent({
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  components: {
    ExclamationCircleFilled,
    Slots,
    Doctor,
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();
    const http = inject("http") as AxiosStatic;
    const { hasPermission } = usePermissions();
    const route = useRoute();

    const loading: Ref<boolean> = ref(false);
    const loadingData: Ref<boolean> = ref(true);
    const loadingProfile: Ref<boolean> = ref(true);
    const data: Ref<OrderExt> = ref({
      id: -1,
      doctor: {
        id: 0,
        firstName: "",
        lastName: "",
        specializations: [{ id: 0, name: "" }],
      },
      start: new Date().toISOString(),
      end: new Date().toISOString(),
      price: 0,
      specializationId: 0,
      country: { id: 0, name: "" },
      adultsCount: 0,
      childrenCount: 0,
      patients: [],
      address: {
        city: "",
        postCode: "",
        streetName: "",
        streetNumber: "",
        country: "",
        flatNumber: "",
        additionalInfo: "",
      },
      email: "",
      phoneNumber: {
        number: "",
        prefix: "",
        pattern: "",
      },
      appointmentType: "CALL" as AppointmentType,
      setDefaultAddress: false,
      forNfz: false,
    });

    const model: OrderShortModel = reactive({
      symptoms: "",
      intraRegulationAgreement: false,
      date: moment(),
      slotIndex: -1,
    });

    const disabledPrevDate: Ref<boolean> = ref(true);
    const loadingSlots: Ref<boolean> = ref(false);
    const loadingReserveSlot: Ref<boolean> = ref(false);
    const slotsVisisble: Ref<boolean> = ref(false);
    const slots: Ref<Array<SlotModel>> = ref([]);
    const slotsError: Ref<boolean> = ref(false);

    const getSlots = async () => {
      disabledPrevDate.value =
        moment(model.date).add(-1, "days") < moment().startOf("day");
      loadingSlots.value = true;

      const tmpDate = model.date.toISOString(true).substring(0, 10);

      model.slotIndex = -1;
      try {
        const slotIntervalsRes = await http.post(
          `visit-api/v1/slot-intervals`,
          {
            day: tmpDate,
            from: slotsConfig.from,
            to: slotsConfig.to,
            languageId: 1,
            intervalSize: slotsConfig.intervalSize(
              "CALL",
              data.value.specializationId
            ),
            appointmentType: "CALL",
            specializationId: route.query.specialization,
            city: null,
          }
        );

        slots.value = [];

        for (const key in slotIntervalsRes.data.availableIntervals) {
          slots.value.push({
            from: new Date(`${tmpDate}T${key}:00.0000`),
            to: new Date(
              new Date(`${tmpDate}T${key}:00.0000`).getTime() +
                slotIntervalsRes.data.intervalSize * 60000
            ),
            disabled: !slotIntervalsRes.data.availableIntervals[key],
          });
        }
      } catch {
        notification.open({
          message: t("ERROR.4981"),
          class: "error",
        });
      } finally {
        loadingSlots.value = false;
      }
    };

    const changeDate = (mode: string) => {
      switch (mode) {
        case "prev": {
          model.date = moment(model.date.add(-1, "days"));
          break;
        }
        case "next": {
          model.date = moment(model.date.add(1, "days"));
          break;
        }
      }
      disabledPrevDate.value =
        moment(model.date).add(-1, "days") < moment().startOf("day");
      getSlots();
    };

    const disabledDate = (current: Moment) => {
      return current && current <= moment().startOf("day");
    };

    const rules: Record<string, Array<RuleObjectExt>> = reactive({
      intraRegulationAgreement: [
        {
          validator: (
            rule: RuleObjectExt,
            value: OrderShortModel["intraRegulationAgreement"]
          ) => {
            return new Promise((resolve, reject) => {
              if (!value) {
                reject(
                  t(
                    "ORDER_SHORT.AGGREMENTS.INTRA_REGULATION_AGREEMENT_REQUIRED"
                  )
                );
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      symptoms: [
        {
          validator: (
            rule: RuleObjectExt,
            value: OrderShortModel["symptoms"]
          ) => {
            return new Promise((resolve, reject) => {
              if (!value) {
                reject(t("ORDER_SHORT.ORDER_SYMPTOMS.SYMPTOM_REQUIRED"));
              }

              resolve();
            });
          },
          trigger: "change",
        },
      ],
    });

    const valid: Ref<boolean> = ref(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const form: any = useForm(model, rules);
    const formTemplate = ref();

    const refreshSlot = (id: number) => {
      return http.post("patient-portal/api/slot/extend", {
        id,
      });
    };

    watch(
      model,
      async () => {
        try {
          await form.validate();
          valid.value = true;
        } catch {
          valid.value = false;
        }
      },
      { deep: true }
    );

    const loadProfile = async () => {
      loadingProfile.value = true;
      const profileData: UserProfile = await http
        .get(`patient-portal/api/patient-profile`)
        .then((res) => res.data);
      data.value.address = {
        ...data.value.address,
        ...profileData.address,
      };
      data.value.phoneNumber = {
        number: profileData.phoneNumber,
        prefix: `+${profileData.phoneNumberPrefix}`,
        pattern:
          phonePrefixOptions[`+${profileData.phoneNumberPrefix}`].pattern,
      };
      data.value.email = profileData.email;

      data.value.patients[0].firstName = profileData.firstName;
      data.value.patients[0].lastName = profileData.lastName;
      data.value.patients[0].identificationDocument = {
        number: profileData.pesel,
        type: "PESEL" as IdType,
      };
      loadingProfile.value = false;
    };

    const loadOrder = async () => {
      loadingData.value = true;
      try {
        data.value = await http
          .get(`patient-portal/api/slot?id=${props.id}`)
          .then((res) => res.data);
        data.value.id = Number(props.id);

        data.value.address = {
          ...data.value.address,
          country: data.value.country.name,
        };
        data.value.patients = [];
        data.value.doctor = {
          ...data.value.doctor,
          avatarUrl: data.value.doctor.avatarUrl
            ? http.defaults.baseURL + "/files/" + data.value.doctor.avatarUrl
            : data.value.doctor.avatarUrl,
        };

        for (let i = 1; i <= data.value.adultsCount; i++) {
          data.value.patients.push({
            type: "ADULT" as PatientType,
            age: "",
            firstName: "",
            lastName: "",
            identificationDocument: {
              number: "",
              type: "PESEL" as IdType,
            },
            birthdate: "",
            predefinedSymptoms: [],
            otherSymptoms: "",
          });
        }

        for (let j = 1; j <= data.value.childrenCount; j++) {
          data.value.patients.push({
            type: "CHILD" as PatientType,
            age: "",
            firstName: "",
            lastName: "",
            identificationDocument: {
              number: "",
              type: "PESEL" as IdType,
            },
            birthdate: "",
            predefinedSymptoms: [],
            otherSymptoms: "",
          });
        }
        const order = assign(store.state.order, { ...data.value });
        store.commit("setOrder", order);

        try {
          await refreshSlot(order.id);
        } catch {
          store.commit("clearOrder");
          notification.open({
            message: t("ERROR.4865"),
            class: "error",
          });
        }
      } catch {
        notification.open({
          message: t("ERROR.4896"),
          class: "error",
        });
      } finally {
        loadingData.value = false;
      }
    };

    const reserveSlot = async (
      priority = "asap",
      specializationId = 1,
      from: Moment,
      to: Moment
    ): Promise<number> => {
      try {
        const orderId: number = await http
          .post(`patient-portal/api/slot/reserve-first-available`, {
            dateTimeFrom: from.format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]"),
            dateTimeTo: to.format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]"),
            specializationId: specializationId,
            appointmentType: "CALL",
            adultsCount: 1,
            childrenCount: 0,
            city: null,
            countryId: 616,
            languageId: 1,
            refreshOffset: 15,
          })
          .then((res) => res.data);
        return orderId;
      } catch (err: any) {
        if (route.query.prority === "asap") {
          to.add(1, "days");
          return reserveSlot(priority, specializationId, from, to);
        } else {
          return 0;
        }
      }
    };

    const showSlots = async () => {
      const findFirstAvailableSlot = (date: Date): number => {
        return findIndex(slots.value, (slot) => {
          return !slot.disabled && slot.from >= date;
        });
      };
      slotsVisisble.value = true;
      model.date = moment(data.value.end);
      await getSlots();
      model.slotIndex = await findFirstAvailableSlot(
        moment(data.value.start).toDate()
      );
    };

    const changeSlot = async () => {
      loadingReserveSlot.value = true;
      const orderId = await reserveSlot(
        route.query.priority?.toString(),
        Number(route.query.specialization),
        moment(slots.value[model.slotIndex].from),
        moment(slots.value[model.slotIndex].to)
      );
      if (orderId) {
        await router.replace({
          name: "Order",
          params: { id: orderId },
          query: route.query,
        });
        slotsError.value = false;
        loadingProfile.value = true;
        slotsVisisble.value = false;
        await loadOrder();
        await loadProfile();
      } else {
        slotsError.value = true;
        const prevSlotIndex = model.slotIndex;
        await getSlots();
        slots.value[prevSlotIndex].class = "error";
        slots.value[prevSlotIndex].disabled = true;
      }
      loadingReserveSlot.value = false;
    };

    const submit = async () => {
      loading.value = true;
      const processToTheNextPage = (order: OrderExt) => {
        store.commit("setOrder", order);
        router.push({
          name: "OrderPayment",
          params: { id: order.id },
        });
      };

      const order = { ...store.state.order };
      order.patients[0].otherSymptoms = model.symptoms;
      order.address = data.value.address;
      order.email = data.value.email;
      order.phoneNumber = data.value.phoneNumber;
      order.patients[0].otherSymptoms = model.symptoms;

      processToTheNextPage(order);
    };

    onMounted(async () => {
      if (!props.id) {
        let from: Moment = moment().add(1, "hours").set("minute", 0);
        let to: Moment = moment().set("hour", 22).set("minute", 0);
        if (route.query.priority === "date") {
          from = moment(
            `${route.query.date} ${route.query.time_start}`,
            "YYYYMMDD HHmm"
          );
          to = moment(
            `${route.query.date} ${route.query.time_end}`,
            "YYYYMMDD HHmm"
          );
        }
        const orderId = await reserveSlot(
          route.query.priority?.toString(),
          Number(route.query.specialization),
          from,
          to
        );

        if (orderId) {
          await router.replace({
            name: "Order",
            params: { id: orderId },
            query: route.query,
          });
          await loadOrder();
          await loadProfile();
        } else {
          loadingData.value = false;
          data.value.patients.push({
            type: "ADULT" as PatientType,
            age: "",
            firstName: "",
            lastName: "",
            identificationDocument: {
              number: "",
              type: "PESEL" as IdType,
            },
            birthdate: "",
            predefinedSymptoms: [],
            otherSymptoms: "",
          });
          model.date = moment(from);
          slotsVisisble.value = true;
          getSlots();
          loadProfile();
        }
      } else {
        await loadOrder();
        await loadProfile();
      }
    });

    return {
      t,
      loading,
      loadingData,
      loadingSlots,
      slots,
      slotsVisisble,
      loadingReserveSlot,
      valid,
      model,
      rules,
      formTemplate,
      changeDate,
      disabledDate,
      changeSlot,
      slotsError,
      loadingProfile,
      disabledPrevDate,
      showSlots,
      getSlots,
      data,
      submit,
      actions,
      hasPermission,
    };
  },
});
export default OrderShortComponent;
